import { useEffect, useState } from "react";
import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const brandNames = [
      "Alfa Romeo",
      "Aston Martin",
      "Audi",
      "Bentley",
      "Rolls Royce",
      "BMW",
      "Chevrolet",
      "Chrysler",
      "Dodge",
      "Ferrari",
      "Fiat",
      "Ford",
      "Honda",
      "Jaguar",
      "Jeep",
      "Lamborghini",
      "Land Rover",
      "Maserati",
      "Mercedes Benz",
      "Mini",
      "Mitsubishi",
      "Nissan",
      "Porsche",
      "Subaru",
      "Suzuki",
      "Toyota",
      "Volkswagen",
      "Volvo",
    ];

    // Cria um array com o nome da marca e caminho da imagem
    const brandList = brandNames.map((name) => ({
      name,
      src: `/img/brands/${name}.png`, // Caminho na pasta public/
    }));

    setBrands(brandList);
  }, []);

  return (
    <>
      <div className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>A Qualidade da Dorman se encontra aqui</h2>
            <br />
            <div
              className="qualidade-dorman"
            >
              <img
                style={{ borderRadius: "1rem" }}
                width={350}
                src="/img/dorman1.jpg" // Agora pegando da public/
                alt="Dorman 1"
              />
              <img
                style={{ borderRadius: "1rem" }}
                width={350}
                src="/img/dorman2.jpg" // Agora pegando da public/
                alt="Dorman 2"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Peças</h2>
            <p>Alguns dos nossos produtos</p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {props.data
                ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>

      {/* Seção das Marcas */}
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Marcas</h2>
            <p>Algumas das marcas que atendemos</p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {brands.length > 0 ? (
                brands.map((brand, i) => (
                  <div
                    key={`${brand.name}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={brand.src}
                      alt={brand.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "contain",
                      }}
                    />
                    <p>{brand.name.replace("-", " ")}</p>
                  </div>

                ))
              ) : (
                "Carregando marcas..."
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
